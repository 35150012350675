import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icons } from '../../data/Assets';

import { SVGIcon } from '../wrappers/SVGIcon';

import { closeModal, isModalActive } from '../../store/slices/modal';

import { getTrackingPath } from '../../helpers/tracking';

const modalName = "LIVE_HELP_MODAL";
const LiveHelp = () => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector(isModalActive(modalName));


  const onClose = () => {
    dispatch(closeModal(modalName));
  }

  useEffect(() => {
    const keyEventListner = (e: any) => {
      if (e.key === 'Escape') {
        onClose();
      }
    }

    const clickEventListner = (e: any) => {
      if (e.target.id === modalName) {
        onClose();
      }
    }

    document.addEventListener('keydown', keyEventListner);
    document.addEventListener('click', clickEventListner);

    return () => {
      document.removeEventListener('keydown', keyEventListner);
      document.removeEventListener('click', clickEventListner);
    }
  })

  if (!isModalVisible) return null;

  const onOpenChat = () => {
    window.location.href = getTrackingPath('/live-help');
  }


  return (
    <div id={modalName} className='imm__live_help__modal_container'>
      <div className='imm__live_help__modal'>
        <div className='imm__live_help__modal__header'>
          <button className='imm__live_help__modal__header__close_btn' onClick={onClose}>
            <SVGIcon src={Icons.X} />
          </button>
          <h3 className='imm__live_help__modal__header__title'>We’re here to help</h3>
        </div>
        <div className='imm__live_help__modal__body'>
          <button className='imm__live_help__modal__body__card' onClick={onOpenChat}>
            <div className='imm__live_help__modal__body__card__icon'>
              <SVGIcon src={Icons.Chat} color='primary' />
            </div>
            <div className='imm__live_help__modal__body__card__content'>
              <h4 className='imm__live_help__modal__body__card__content__title'>Live Chat</h4>
              <div className='imm__live_help__modal__body__card__content__body'>
                <span className='imm__live_help__modal__body__card__content__online_icon' />
                <p className='imm__live_help__modal__body__card__content__online_text'>Online now</p>
              </div>
            </div>
          </button>
          <div className='imm__live_help__modal__body__card'>
            <div className='imm__live_help__modal__body__card__icon'>
              <SVGIcon src={Icons.Mail} color='primary' />
            </div>
            <div className='imm__live_help__modal__body__card__content'>
              <h4 className='imm__live_help__modal__body__card__content__title'>help@immproved.com</h4>
              <div className='imm__live_help__modal__body__card__content__body'>
                <span className='imm__live_help__modal__body__card__content__online_icon' />
                <p className='imm__live_help__modal__body__card__content__online_text'>Email response in 12 hours</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveHelp