import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import "bootstrap/dist/css/bootstrap.min.css";
import "../fontawesome-5/css/all.min.css";
import '../styles/index.scss';

import PageLoader from '../components/older/Components/Loader';

import MessageModal from './modals/Message';
import PackagesModal from './modals/Packages';
import PaymentModal from './modals/Payment';
import ReportBugModal from './modals/Report';
import CancelSubscriptionModal from './modals/CancelSubscription';
import SpouseAddedMessage from './modals/SpouseAddedMessage';
import OrganizationInvitationModal from './modals/OrganizationInvitation';
import OnbordingModal from './modals/Onbording';
import ManageConnectionModal from './modals/ManageConnection';
import ExpiryNotificationModal from './modals/ExpiryNotificationModal';
import OrganizationInvitationsListModal from './modals/OrganizationInvitationsList';
import ProgramFilterModal from './modals/ProgramFilter';
import TwoFactorAuthenticationModal from './modals/TwoFactorAuthentication';
import IncompleteQuestionnaireModal from './modals/IncompleteQuestionnaire';
import LiveHelpModal from './modals/LiveHelp';

import Translator from './wrappers/Translator';

import { closeAllModals, openModal, closeModal } from '../store/slices/modal';

import cookiesHelper from '../helpers/cookies';
import authHelper from '../helpers/auth';

import useIdleTimeout from "../hooks/useIdleTimeout";
import useRefreshAuthToken from '../hooks/auth/useRefreshAuthToken';
import useSignOut from '../hooks/auth/useSignOut';
import useGetConnections from '../hooks/invitations/useGetConnections';

const App = (props: any) => {
    const { children } = props;

    const location = useLocation();
    const dispatch = useDispatch();

    const { recent_login } = location.state || { recent_login: false };

    const { data: invitations } = useGetConnections(recent_login);
    const { mutate: signOut } = useSignOut();
    const { mutate: refreshAuthToken } = useRefreshAuthToken();

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(closeAllModals());
        // eslint-disable-next-line
    }, [location]);

    const onOpenOrgInvitationsList = (data: any) => {
        return dispatch(openModal({ name: 'ORGANIZATION_INVITATIONS_LIST_MODAL', data }));
    };

    useEffect(() => {
        if (invitations?.organizationInvitations?.filter((invitation: any) => invitation?.status === 'pending')?.length && recent_login) {
            dispatch(closeModal('ON_BORDING_MODAL'));

            onOpenOrgInvitationsList(invitations.organizationInvitations || []);
        }
    }, [invitations, recent_login]) // eslint-disable-line    

    const staySigned = async (variables: any) => {
        dispatch(closeModal('MESSAGE_MODAL'));

        refreshAuthToken({
            successCallback: (auth_token: any) => {
                cookiesHelper.setCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string, auth_token, 30 * 60 * 1000);
            },
            errorCallback: () => {
                signOut({ applicantKey: 'main' });
            }
        });
    };

    const onActionIp = () => {
        const tokenValidationRes = authHelper.isAuthTokenValid();
        if (!tokenValidationRes.result) {
            if (tokenValidationRes.reason !== 'not_found') signOut({ applicantKey: 'main' });
        } else {
            const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
            const tokenPayload = JSON.parse(atob(token?.split('.')[1] as string));
            const tokenExp = tokenPayload.exp;
            const dateNow = new Date();

            if ((tokenExp - (dateNow.getTime() / 1000)) <= (4 * 60)) {
                staySigned(null);
            };
        }
    };

    const handleIdle = () => {
        if (authHelper.isAuthTokenValid().result) {
            return dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    icon: 'info',
                    title: 'Are you still there?',
                    titleColor: 'primary',
                    content: 'For security reasons, you will be logged out in 5 minutes if you do not respond.',
                    buttons: [
                        { label: 'Stay Signed In', style: 'primary', onClick: staySigned },
                        { label: 'Logout', style: 'info', onClick: signOut },
                    ]
                },
            }));
        };
    };

    // eslint-disable-next-line
    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, onActionIp: onActionIp, idleTime: (25 * 60) });

    return (
        <Suspense fallback={<PageLoader overlay="true" />}>
            {process.env.REACT_APP_APP_ENV !== 'STAGING' && process.env.REACT_APP_APP_ENV !== 'PRODUCTION' &&
                <Translator
                    pageLanguage="en"
                    autoDisplay={false}
                    includedLanguages={[
                        { label: 'English', value: 'en' },
                        { label: 'French', value: 'fr' },
                        { label: 'Spanish', value: 'es' },
                    ]}
                />
            }
            <MessageModal />
            <PackagesModal />
            <PaymentModal />
            <ReportBugModal />
            <CancelSubscriptionModal />
            <SpouseAddedMessage />
            <OrganizationInvitationModal />
            <OrganizationInvitationsListModal />
            <OnbordingModal />
            <ManageConnectionModal />
            <ExpiryNotificationModal />
            <ProgramFilterModal />
            <TwoFactorAuthenticationModal />
            <IncompleteQuestionnaireModal />
            <LiveHelpModal />
            <div className="imm__immproved_app">
                {children}
            </div>
        </Suspense>
    );
};

App.propTypes = {
    children: PropTypes.element.isRequired,
};

export default App;