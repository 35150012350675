import { getClient } from "../helpers/utility";

const BUCKET_ICONS_URL = `icons`;
const BUCKET_IMAGES_URL = `images`;
const BUCKET_MEDIA_URL = `media`;
const BUCKET_LOGOS_URL = `logos`;

export const Icons = {
    Ai: `${BUCKET_ICONS_URL}/ai.svg`,
    AiChat: `${BUCKET_ICONS_URL}/ai-chat.svg`,
    AiChatFill: `${BUCKET_ICONS_URL}/ai-chat-fill.svg`,
    AiFill: `${BUCKET_ICONS_URL}/ai-fill.svg`,
    Arrow: `${BUCKET_ICONS_URL}/arrow.svg`,
    Arrow2: `${BUCKET_ICONS_URL}/arrow2.svg`,
    ArrowDownUp: `${BUCKET_ICONS_URL}/arrow-down-up.svg`,
    ArrowShort: `${BUCKET_ICONS_URL}/arrow-short.svg`,
    Attach: `${BUCKET_ICONS_URL}/attach.svg`,
    Bell: `${BUCKET_ICONS_URL}/bell.svg`,
    Bell2: `${BUCKET_ICONS_URL}/bell2.svg`,
    Bell2Fill: `${BUCKET_ICONS_URL}/bell2-fill.svg`,
    BellFill: `${BUCKET_ICONS_URL}/bell-fill.svg`,
    Bookmark: `${BUCKET_ICONS_URL}/bookmark.svg`,
    BookmarkFill: `${BUCKET_ICONS_URL}/bookmark-fill.svg`,
    Briefcase: `${BUCKET_ICONS_URL}/briefcase.svg`,
    Briefcase2: `${BUCKET_ICONS_URL}/briefcase2.svg`,
    Briefcase2Fill: `${BUCKET_ICONS_URL}/briefcase2-fill.svg`,
    BriefcaseFill: `${BUCKET_ICONS_URL}/briefcase-fill.svg`,
    Building: `${BUCKET_ICONS_URL}/building.svg`,
    BuildingFill: `${BUCKET_ICONS_URL}/building-fill.svg`,
    Calendar: `${BUCKET_ICONS_URL}/calendar.svg`,
    Calendar2: `${BUCKET_ICONS_URL}/calendar2.svg`,
    Calendar2Fill: `${BUCKET_ICONS_URL}/calendar2-fill.svg`,
    Calendar3: `${BUCKET_ICONS_URL}/calendar3.svg`,
    Calendar3Fill: `${BUCKET_ICONS_URL}/calendar3-fill.svg`,
    Calendar4: `${BUCKET_ICONS_URL}/calendar4.svg`,
    Calendar4Fill: `${BUCKET_ICONS_URL}/calendar4-fill.svg`,
    CalendarFill: `${BUCKET_ICONS_URL}/calendar-fill.svg`,
    CanadaFlagCircle: `${BUCKET_ICONS_URL}/canada-flag-circle.svg`,
    Chat: `${BUCKET_ICONS_URL}/chat.svg`,
    Chat2: `${BUCKET_ICONS_URL}/chat2.svg`,
    Chat2Fill: `${BUCKET_ICONS_URL}/chat2-fill.svg`,
    Chat3: `${BUCKET_ICONS_URL}/chat3.svg`,
    Chat3Fill: `${BUCKET_ICONS_URL}/chat3-fill.svg`,
    ChatFill: `${BUCKET_ICONS_URL}/chat-fill.svg`,
    ChatQuestion: `${BUCKET_ICONS_URL}/chat-question.svg`,
    CheckCircle: `${BUCKET_ICONS_URL}/check-circle.svg`,
    CheckCircleFill: `${BUCKET_ICONS_URL}/check-circle-fill.svg`,
    CheckCircleHalfDashed: `${BUCKET_ICONS_URL}/check-circle-half-dashed.svg`,
    CheckCircle3Quarters: `${BUCKET_ICONS_URL}/check-circle-three-quarters.svg`,
    Checkmark: `${BUCKET_ICONS_URL}/checkmark.svg`,
    CheckShield: `${BUCKET_ICONS_URL}/check-shield.svg`,
    CheckShieldFill: `${BUCKET_ICONS_URL}/check-shield-fill.svg`,
    Chevron: `${BUCKET_ICONS_URL}/chevron.svg`,
    ChevronCircle: `${BUCKET_ICONS_URL}/chevron-circle.svg`,
    ChevronCircleFill: `${BUCKET_ICONS_URL}/chevron-circle-fill.svg`,
    ChevronDouble: `${BUCKET_ICONS_URL}/chevron-double.svg`,
    ChevronDoubleSharp: `${BUCKET_ICONS_URL}/chevron-double-sharp.svg`,
    ChevronSharp: `${BUCKET_ICONS_URL}/chevron-sharp.svg`,
    ChevronThick: `${BUCKET_ICONS_URL}/chevron-thick.svg`,
    ClockCircle: `${BUCKET_ICONS_URL}/clock-circle.svg`,
    ClockCircleFill: `${BUCKET_ICONS_URL}/clock-circle-fill.svg`,
    ClockCircle3QuartersReverseArrow: `${BUCKET_ICONS_URL}/clock-circle-three-quarters-reverse-arrow.svg`,
    CloudUpload: `${BUCKET_ICONS_URL}/cloud-upload.svg`,
    CloudUploadFill: `${BUCKET_ICONS_URL}/cloud-upload-fill.svg`,
    Comment: `${BUCKET_ICONS_URL}/comment.svg`,
    CommentFill: `${BUCKET_ICONS_URL}/comment-fill.svg`,
    Crown: `${BUCKET_ICONS_URL}/crown.svg`,
    CrownFill: `${BUCKET_ICONS_URL}/crown-fill.svg`,
    DashCircle: `${BUCKET_ICONS_URL}/dash-circle.svg`,
    DashCircleFill: `${BUCKET_ICONS_URL}/dash-circle-fill.svg`,
    DocDownload: `${BUCKET_ICONS_URL}/doc-download.svg`,
    DocDownloadFill: `${BUCKET_ICONS_URL}/doc-download-fill.svg`,
    DocEdit: `${BUCKET_ICONS_URL}/doc-edit.svg`,
    DocSave: `${BUCKET_ICONS_URL}/doc-save.svg`,
    DocSaveFill: `${BUCKET_ICONS_URL}/doc-save-fill.svg`,
    DocSearch: `${BUCKET_ICONS_URL}/doc-search.svg`,
    Document: `${BUCKET_ICONS_URL}/document.svg`,
    Document2: `${BUCKET_ICONS_URL}/document2.svg`,
    Document2Fill: `${BUCKET_ICONS_URL}/document2-fill.svg`,
    Document3: `${BUCKET_ICONS_URL}/document3.svg`,
    Document3Fill: `${BUCKET_ICONS_URL}/document3-fill.svg`,
    DollarCircle: `${BUCKET_ICONS_URL}/dollar-circle.svg`,
    DollarCircleFill: `${BUCKET_ICONS_URL}/dollar-circle-fill.svg`,
    DotsCircle: `${BUCKET_ICONS_URL}/dots-circle.svg`,
    DotsCircleFill: `${BUCKET_ICONS_URL}/dots-circle-fill.svg`,
    Download: `${BUCKET_ICONS_URL}/download.svg`,
    Download2: `${BUCKET_ICONS_URL}/download2.svg`,
    Download3: `${BUCKET_ICONS_URL}/download3.svg`,
    Download4: `${BUCKET_ICONS_URL}/download4.svg`,
    Duplicate: `${BUCKET_ICONS_URL}/duplicate.svg`,
    DuplicateFill: `${BUCKET_ICONS_URL}/duplicate-fill.svg`,
    Edit: `${BUCKET_ICONS_URL}/edit.svg`,
    EditCircle: `${BUCKET_ICONS_URL}/edit-circle.svg`,
    EditCircleFill: `${BUCKET_ICONS_URL}/edit-circle-fill.svg`,
    EditFill: `${BUCKET_ICONS_URL}/edit-fill.svg`,
    ExclamationCircle: `${BUCKET_ICONS_URL}/exclamation-circle.svg`,
    ExclamationCircleFill: `${BUCKET_ICONS_URL}/exclamation-circle-fill.svg`,
    ExclamationTriangle: `${BUCKET_ICONS_URL}/exclamation-triangle.svg`,
    ExclamationTriangleFill: `${BUCKET_ICONS_URL}/exclamation-triangle-fill.svg`,
    Eye: `${BUCKET_ICONS_URL}/eye.svg`,
    EyeFill: `${BUCKET_ICONS_URL}/eye-fill.svg`,
    EyeOff: `${BUCKET_ICONS_URL}/eye-off.svg`,
    EyeOff2Fill: `${BUCKET_ICONS_URL}/eye-off2-fill.svg`,
    EyeOffFill: `${BUCKET_ICONS_URL}/eye-off-fill.svg`,
    FacebookSquare: `${BUCKET_ICONS_URL}/facebook-square.svg`,
    FacebookSquareFill: `${BUCKET_ICONS_URL}/facebook-square-fill.svg`,
    Filter: `${BUCKET_ICONS_URL}/filter.svg`,
    Gear: `${BUCKET_ICONS_URL}/gear.svg`,
    GearFill: `${BUCKET_ICONS_URL}/gear-fill.svg`,
    GradHat: `${BUCKET_ICONS_URL}/grad-hat.svg`,
    GradHatFill: `${BUCKET_ICONS_URL}/grad-hat-fill.svg`,
    Heart: `${BUCKET_ICONS_URL}/heart.svg`,
    HeartFill: `${BUCKET_ICONS_URL}/heart-fill.svg`,
    Home: `${BUCKET_ICONS_URL}/home.svg`,
    HomeFill: `${BUCKET_ICONS_URL}/home-fill.svg`,
    ImageUpload: `${BUCKET_ICONS_URL}/image-upload.svg`,
    InfoCircle: `${BUCKET_ICONS_URL}/info-circle.svg`,
    InfoCircleFill: `${BUCKET_ICONS_URL}/info-circle-fill.svg`,
    InstagramSquare: `${BUCKET_ICONS_URL}/instagram-square.svg`,
    InstagramSquareFill: `${BUCKET_ICONS_URL}/instagram-square-fill.svg`,
    JobSearch: `${BUCKET_ICONS_URL}/job-search.svg`,
    LinkedinSquare: `${BUCKET_ICONS_URL}/linkedin-square.svg`,
    LinkedinSquareFill: `${BUCKET_ICONS_URL}/linkedin-square-fill.svg`,
    Location: `${BUCKET_ICONS_URL}/location.svg`,
    LocationFill: `${BUCKET_ICONS_URL}/location-fill.svg`,
    Lock: `${BUCKET_ICONS_URL}/lock.svg`,
    Lock2: `${BUCKET_ICONS_URL}/lock2.svg`,
    Lock2Fill: `${BUCKET_ICONS_URL}/lock2-fill.svg`,
    LockFill: `${BUCKET_ICONS_URL}/lock-fill.svg`,
    LogOff: `${BUCKET_ICONS_URL}/log-off.svg`,
    Mail: `${BUCKET_ICONS_URL}/mail.svg`,
    MailFill: `${BUCKET_ICONS_URL}/mail-fill.svg`,
    Mic: `${BUCKET_ICONS_URL}/mic.svg`,
    Mic2: `${BUCKET_ICONS_URL}/mic2.svg`,
    Mic2Fill: `${BUCKET_ICONS_URL}/mic2-fill.svg`,
    MicFill: `${BUCKET_ICONS_URL}/mic-fill.svg`,
    Minimize: `${BUCKET_ICONS_URL}/minimize.svg`,
    NetworkCard: `${BUCKET_ICONS_URL}/network-card.svg`,
    News: `${BUCKET_ICONS_URL}/news.svg`,
    News2: `${BUCKET_ICONS_URL}/news2.svg`,
    Pathways: `${BUCKET_ICONS_URL}/pathways.svg`,
    PauseCircle: `${BUCKET_ICONS_URL}/pause-circle.svg`,
    PauseCircleFill: `${BUCKET_ICONS_URL}/pause-circle-fill.svg`,
    Plane: `${BUCKET_ICONS_URL}/plane.svg`,
    PlayCircle: `${BUCKET_ICONS_URL}/play-circle.svg`,
    PlayCircleFill: `${BUCKET_ICONS_URL}/play-circle-fill.svg`,
    Plus: `${BUCKET_ICONS_URL}/plus.svg`,
    PlusCircle: `${BUCKET_ICONS_URL}/plus-circle.svg`,
    PlusCircleFill: `${BUCKET_ICONS_URL}/plus-circle-fill.svg`,
    PlusSquareDotted: `${BUCKET_ICONS_URL}/plus-square-dotted.svg`,
    Point: `${BUCKET_ICONS_URL}/point.svg`,
    PointFill: `${BUCKET_ICONS_URL}/point-fill.svg`,
    QuestionChat: `${BUCKET_ICONS_URL}/question-chat.svg`,
    QuestionChatFill: `${BUCKET_ICONS_URL}/question-chat-fill.svg`,
    QuestionCircle: `${BUCKET_ICONS_URL}/question-circle.svg`,
    QuestionCircleFill: `${BUCKET_ICONS_URL}/question-circle-fill.svg`,
    Reload: `${BUCKET_ICONS_URL}/reload.svg`,
    RiseCircle: `${BUCKET_ICONS_URL}/rise-circle.svg`,
    Scanner: `${BUCKET_ICONS_URL}/scanner.svg`,
    Search: `${BUCKET_ICONS_URL}/search.svg`,
    Search2: `${BUCKET_ICONS_URL}/search2.svg`,
    Search2Fill: `${BUCKET_ICONS_URL}/search2-fill.svg`,
    SearchFill: `${BUCKET_ICONS_URL}/search-fill.svg`,
    Send: `${BUCKET_ICONS_URL}/send.svg`,
    SendFill: `${BUCKET_ICONS_URL}/send-fill.svg`,
    Share: `${BUCKET_ICONS_URL}/share.svg`,
    ShareFill: `${BUCKET_ICONS_URL}/share-fill.svg`,
    SoundOff: `${BUCKET_ICONS_URL}/sound-off.svg`,
    SoundOffFill: `${BUCKET_ICONS_URL}/sound-off-fill.svg`,
    SoundOn: `${BUCKET_ICONS_URL}/sound-on.svg`,
    SoundOnFill: `${BUCKET_ICONS_URL}/sound-on-fill.svg`,
    Star: `${BUCKET_ICONS_URL}/star.svg`,
    StarCircle: `${BUCKET_ICONS_URL}/star-circle.svg`,
    StarCircleFill: `${BUCKET_ICONS_URL}/star-circle-fill.svg`,
    StarFill: `${BUCKET_ICONS_URL}/star-fill.svg`,
    StopCircle: `${BUCKET_ICONS_URL}/stop-circle.svg`,
    StopCircleFill: `${BUCKET_ICONS_URL}/stop-circle-fill.svg`,
    Tag: `${BUCKET_ICONS_URL}/tag.svg`,
    TagFill: `${BUCKET_ICONS_URL}/tag-fill.svg`,
    ThreeDotsVertical: `${BUCKET_ICONS_URL}/three-dots-vertical.svg`,
    Trash: `${BUCKET_ICONS_URL}/trash.svg`,
    Trash2: `${BUCKET_ICONS_URL}/trash2.svg`,
    Trash2Fill: `${BUCKET_ICONS_URL}/trash2-fill.svg`,
    Trash3: `${BUCKET_ICONS_URL}/trash3.svg`,
    Trash3Fill: `${BUCKET_ICONS_URL}/trash3-fill.svg`,
    TrashFill: `${BUCKET_ICONS_URL}/trash-fill.svg`,
    TriangleFill: `${BUCKET_ICONS_URL}/triangle-fill.svg`,
    TriangleUpDownFill: `${BUCKET_ICONS_URL}/triangle-up-down-fill.svg`,
    Upload: `${BUCKET_ICONS_URL}/upload.svg`,
    Upload2: `${BUCKET_ICONS_URL}/upload2.svg`,
    UserBook: `${BUCKET_ICONS_URL}/user-book.svg`,
    UserCard: `${BUCKET_ICONS_URL}/user-card.svg`,
    UserCard2: `${BUCKET_ICONS_URL}/user-card2.svg`,
    UserCircle: `${BUCKET_ICONS_URL}/user-circle.svg`,
    UserCircleFill: `${BUCKET_ICONS_URL}/user-circle-fill.svg`,
    Users: `${BUCKET_ICONS_URL}/users.svg`,
    UsersChat: `${BUCKET_ICONS_URL}/users-chat.svg`,
    UsersCircle: `${BUCKET_ICONS_URL}/users-circle.svg`,
    UsersCircleFill: `${BUCKET_ICONS_URL}/users-circle-fill.svg`,
    UsersConnect: `${BUCKET_ICONS_URL}/users-connect.svg`,
    UsersFill: `${BUCKET_ICONS_URL}/users-fill.svg`,
    UserSpeak: `${BUCKET_ICONS_URL}/user-speak.svg`,
    X: `${BUCKET_ICONS_URL}/x.svg`,
    XCircle: `${BUCKET_ICONS_URL}/x-circle.svg`,
    XCircleFill: `${BUCKET_ICONS_URL}/x-circle-fill.svg`,
};

export const Images = {
    FlyingPaperPlane: `${BUCKET_IMAGES_URL}/flying_paper_plane.png`,
    MobileSignupBanner: `${BUCKET_IMAGES_URL}/mobile_signup_banner.png`,
    PaymentBanner1: `${BUCKET_IMAGES_URL}/payment_bg1.png`,
    PaymentBanner2: `${BUCKET_IMAGES_URL}/payment_bg2.png`,
    PaymentBanner3: `${BUCKET_IMAGES_URL}/payment_bg3.png`,
    ResetPassword: `${BUCKET_IMAGES_URL}/reset_password.png`,
    SuccessCelebrate: `${BUCKET_IMAGES_URL}/success_celebrate.png`,
    WebLandingBanner: `${BUCKET_IMAGES_URL}/web_landing_world.png`,
    BookConsultBanner: `${BUCKET_IMAGES_URL}/book_consult.png`,
    BookConsult2Banner: `${BUCKET_IMAGES_URL}/book_consult_v2.png`,
    MeetConsultBanner: `${BUCKET_IMAGES_URL}/meet_consult.png`,
    WantMoreOptions: `${BUCKET_IMAGES_URL}/want_more_options.png`,
    highestScore: `${BUCKET_IMAGES_URL}/highest_score.png`,
    yourScore: `${BUCKET_IMAGES_URL}/your_score.png`,
    ScoreMatrix: `${BUCKET_IMAGES_URL}/score_matrix.png`,
    NoBs: `${BUCKET_IMAGES_URL}/no_bs.png`,
    UpArrow: `${BUCKET_IMAGES_URL}/up-arrow.svg`,
    VisaChancesHigh: `${BUCKET_IMAGES_URL}/visa-chances-high.png`,
    VisaChancesMedium: `${BUCKET_IMAGES_URL}/visa-chances-medium.png`,
    VisaChancesLow: `${BUCKET_IMAGES_URL}/visa-chances-low.png`,
    VisaChancesPromptHigh: `${BUCKET_IMAGES_URL}/visa-chances-prompt-high.png`,
    VideoCall: `${BUCKET_IMAGES_URL}/video_call.svg`,
    StarReview: `${BUCKET_IMAGES_URL}/star_review.svg`,
    TrustShield: `${BUCKET_IMAGES_URL}/trust_shield.svg`,
    Google: `${BUCKET_IMAGES_URL}/google.svg`,
    Facebook: `${BUCKET_IMAGES_URL}/facebook.svg`,
    Leaf: `${BUCKET_IMAGES_URL}/leaf.png`,
    Rating: `${BUCKET_IMAGES_URL}/rating.png`,
    FiftyYears: `${BUCKET_IMAGES_URL}/50yrs.svg`,
    Qualify: `${BUCKET_IMAGES_URL}/qualify.png`,
    EmailAuthenticator: `${BUCKET_IMAGES_URL}/email_authenticator.png`,
    GoogleAuthenticator: `${BUCKET_IMAGES_URL}/google_authenticator.png`,
    MicrosoftAuthenticator: `${BUCKET_IMAGES_URL}/microsoft_authenticator.png`,
    PhoneAuthenticator: `${BUCKET_IMAGES_URL}/phone_authenticator.png`,
    SSO: `${BUCKET_IMAGES_URL}/sso.svg`,
    Plane: `${BUCKET_IMAGES_URL}/plane.svg`,
    Translate: `${BUCKET_IMAGES_URL}/translate_logo.png`,
};

export const Media = {
    Rocket: `${BUCKET_MEDIA_URL}/rocket.mp4`,
};

interface LogoSet {
    LogoWhite: string;
    Logo: string;
    Monogram: string;
};
export const getLogoSet = (): LogoSet => {
    const client = getClient();

    return {
        LogoWhite: `${BUCKET_LOGOS_URL}/${client}_white.svg`,
        Logo: `${BUCKET_LOGOS_URL}/${client}_color.svg`,
        Monogram: `${BUCKET_LOGOS_URL}/${client}_monogram_color.svg`,
    };
};  
