import React from 'react'

import useSaveTravelDocs from '../../../hooks/passports/useSaveTravelDocs';
import useSaveApplicantDetail from '../../../hooks/applicants/useSaveApplicant';

import { SVGIcon } from '../../wrappers/SVGIcon';

import { Icons } from '../../../data/Assets';

const ExpiryNotificationView = (props: any) => {
    const { currentApplicantKey, documentType, notifications, isProcessing, setIsProcessing, setApiError, documentPayload, onClose } = props;

    const { mutate: saveTravelDocs } = useSaveTravelDocs();
    const { mutate: saveApplicantDetail } = useSaveApplicantDetail();

    const handleModalClose = () => {
        setIsProcessing(false);
        onClose();
    }

    const handleSubmit = () => {
        if (documentType === 'SIN') {
            saveApplicantDetail({
                applicantKey: currentApplicantKey,
                data: {
                    sin_expiry: documentPayload.sin_expiry,
                },
                setIsProcessing,
                setApiError,
            })
        } else {
            saveTravelDocs({
                payload: documentPayload,
                setIsProcessing,
            });
        }
        setIsProcessing(false);

        onClose();
    };

    return (
        <div className="imm__modal_body imm__modal__expiry_notification">
            <br />
            <h4 className="expiry_notification__title">
                <SVGIcon src={Icons.ExclamationCircleFill} color='error' /> Your {currentApplicantKey === 'spouse' ? "Spouse's" : ''} documents are expiring!
            </h4>
            <span className="expiry_notification__subtitle">
                Following notifications will be generated:
            </span>
            <br />

            <div className="expiry_notification__content">
                {notifications.map((notification: any, index: number) => (
                    <div className='expiry_notification__content_items' key={index}>
                        <p className="expiry_notification__content_title">
                            <span className='expiry_notification__content_title_pt' />
                            <span>{notification.title}</span>
                        </p>
                        <p className='expiry_notification__content_sec'>{notification.description}</p>
                    </div>
                ))}
            </div>

            <br />
            <div className="imm__expiry_notification__modal_footer">
                <button className="imm__expiry_notification__modal_btn imm__btn__secondary" onClick={handleModalClose}>Go Back</button>
                <button
                    className={`imm__expiry_notification__modal_btn btn_primary imm__btn__primary`}
                    onClick={handleSubmit}
                >
                    {
                        isProcessing
                            ? <i className='imm__btn_loader' />
                            : 'Proceed'
                    }
                </button>
            </div>
        </div>
    );
}

export default ExpiryNotificationView;