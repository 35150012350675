import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SVGIcon } from "../wrappers/SVGIcon";

import { Icons, getLogoSet } from "../../data/Assets";

import useGetPackages from "../../hooks/subscriptions/useGetPackages";
import useCreatePaymentIntent from "../../hooks/subscriptions/useCreatePaymentIntent";

import { triggerEvent } from "../../helpers/tracking";

import { getSessionData, getUserSubscription } from "../../store/slices/global";
import { closeModal, openModal } from "../../store/slices/modal";
import { ImageAsset } from "../wrappers/ImageAsset";

const PlanCard = (props: any) => {
    const dispatch = useDispatch();
    const sessionData = useSelector(getSessionData());
    const userSubscription = useSelector(getUserSubscription());

    const { recommended, title, planType, activePlanType, setActivePlanType, price, price_descr, features_title, features_descr, features, bonus_text, selectedPackage } = props;

    const [isProcessRunning, setIsProcessRunning] = useState(false);

    const { mutate: createPaymentIntent } = useCreatePaymentIntent();

    const onCreatePaymentIntent = (e: any) => {
        e.preventDefault();

        triggerEvent({
            name: 'subscription_chosen',
            action: 'click',
            category: 'Subscription',
            email: (sessionData as any).email,
            user_id: (sessionData as any).user_id,
            items: [{
                item_id: (selectedPackage as any).id,
                item_name: (selectedPackage as any).subscription_months > 1 ? 'annual' : 'monthly',
                quantity: 1,
                price: Number((selectedPackage as any).amount),
            }],
            currency: 'CAD',
            pkg_amount: Number((selectedPackage as any).amount),
            pkg_consults: (selectedPackage as any).consults,
            pkg_months: (selectedPackage as any).subscription_months,
        });

        setIsProcessRunning(true);

        createPaymentIntent({
            payload: {
                currency: 'cad',
                payment_method_type: 'card',
                amount: Number((selectedPackage as any).amount),
            },
            successCallback: async (paymentIntent: any) => {
                if (paymentIntent) {

                    triggerEvent({
                        name: 'begin_checkout',
                        action: 'click',
                        category: 'Subscription',
                        email: (sessionData as any).email,
                        user_id: (sessionData as any).user_id,
                        value: Number((selectedPackage as any).amount),
                        currency: 'CAD',
                        coupon: 'N/A',
                        items: [{
                            item_id: selectedPackage.id,
                            item_name: selectedPackage.subscription_months > 1 ? 'annual' : 'monthly',
                            quantity: 1,
                            price: Number(selectedPackage.amount),
                        }],
                        pkg_amount: Number(selectedPackage.amount),
                        pkg_consults: selectedPackage.consults,
                        pkg_months: selectedPackage.subscription_months,
                    });

                    dispatch(closeModal('PACKAGES_MODAL'));

                    dispatch(openModal({
                        name: 'PAYMENT_MODAL',
                        data: {
                            package: selectedPackage,
                            paymentOptions: {
                                paymentIntentId: paymentIntent.id,
                                clientSecret: paymentIntent.client_secret,
                                appearance: {
                                    theme: 'stripe'
                                }
                            },
                        }
                    }));
                } else {
                    console.error('Error creating stripe session');
                    setIsProcessRunning(false);
                }
            },
            errorCallback: (err: any) => {
                console.error('Error creating stripe session');
                setIsProcessRunning(false);
            }
        });
    };

    const handleGetStarted = (e: any) => {
        if (isProcessRunning) return;

        if (activePlanType !== planType) {
            return setActivePlanType(planType);
        };

        if (!selectedPackage) {
            return;
        } else {
            if (userSubscription) {
                if (Number((userSubscription as any).package.amount) >= Number((selectedPackage as any).amount)) {
                    return;
                };
            };

            onCreatePaymentIntent(e);
        };
    };

    return (
        <div className={`imm__packages_component__plans__plan_card ${recommended ? 'imm__pcppc__recommended' : ''} ${activePlanType !== planType ? 'imm__pcppc__inactive' : ''} ${userSubscription && Number((userSubscription as any).package.amount) >= Number(selectedPackage?.amount ?? 0) ? 'imm__pcppc__disabled' : ''}`}>
            <div className='imm__packages_component__plans__plan_card__header'>
                <p>{title}</p>
                {recommended && <span>Recommended</span>}
            </div>

            <div className='imm__packages_component__plans__plan_card__body'>
                <div className='imm__packages_component__plans__plan_card__price'>
                    {
                        activePlanType !== planType
                            ? <p className="imm__pcppcp__price__inactive_text">{planType.charAt(0).toUpperCase() + planType.slice(1)} Plan Only</p>
                            : <>
                                <p className="imm__pcppcp__price">
                                    <span className="imm__pcppcp__price__main">${planType === 'monthly' ? price : (price / 12).toString().substring(0, 5)}</span>
                                    <span className="imm__pcppcp__price__sub">&nbsp;CAD / month</span>
                                </p>
                                <p className="imm__pcppcp__description">{price_descr}</p>
                                <p className="imm__pcppcp__bonus_text">{bonus_text}</p>
                            </>
                    }
                </div>

                <button
                    className={`imm__packages_component__plans__plan_card__btn ${(userSubscription && Number((userSubscription as any).package.amount) >= Number(selectedPackage?.amount ?? 0)) ? 'imm__pcppcb__disabled' : ''}`}
                    onClick={handleGetStarted}
                >
                    <span>{activePlanType !== planType ? 'See Price' : 'Get started'}</span>
                    {
                        isProcessRunning
                            ? <i className='imm__btn_loader' />
                            : <SVGIcon src={Icons.ArrowShort} rotation={270} color="primary" />
                    }
                </button>

                <div className='imm__packages_component__plans__plan_card__features__container'>
                    <div className="imm__pcppcf__head">
                        <p className="imm__pcppcf__title">{features_title}</p>
                        <p className="imm__pcppcf__description">{features_descr}</p>
                    </div>

                    <div className='imm__pcppcf__features'>
                        {features.map((feature: any, index: any) => (
                            <div key={index} className='imm__pcppcf__features__feature'>
                                <SVGIcon
                                    src={Icons.CheckCircle}
                                    color={activePlanType !== planType || (userSubscription && Number((userSubscription as any).package.amount) >= Number(selectedPackage?.amount ?? 0)) ? "grey" : "success"}
                                />
                                <p>{feature}</p>
                            </div>
                        ))}
                    </div>

                    <p className="imm__packages_component__plans__plan_card__bonus_text">
                        {bonus_text}
                    </p>
                </div>
            </div>
        </div >
    );
};

const AllSeasonCard = () => {

    const getQuote = () => {
        window.location.href = 'mailto:info@getimmproved.com';
    };

    return (
        <div className='imm__packages_component__plans__plan_card imm__packages_component__plans__plan_card__four_seasons'>
            <div className='imm__packages_component__plans__plan_card__header'>
                <p>Four Seasons - Full Guidance</p>
            </div>

            <div className='imm__packages_component__plans__plan_card__body'>
                <div className="imm__packages_component__plans__plan_card__body__column">
                    <div className='imm__packages_component__plans__plan_card__price'>
                        <p className="imm__pcppcp__description">
                            Looking for assistance with your immigration application?  Here are some of the services offered by our trusted partners. Immproved can assist you with navigating the right partner and ensure a smooth and trusted service.
                        </p>
                    </div>

                    <button
                        className='imm__packages_component__plans__plan_card__btn'
                        onClick={getQuote}
                    >
                        <span>Get a Quote</span>
                        <SVGIcon src={Icons.ArrowShort} rotation={270} color="primary" />
                    </button>
                </div>

                <div className="imm__packages_component__plans__plan_card__body__column">
                    <div className='imm__packages_component__plans__plan_card__features__container'>
                        <div className="imm__pcppcf__head">
                            <p className="imm__pcppcf__title">Everything in Premium, plus:</p>
                        </div>

                        <div className='imm__pcppcf__features'>
                            <div className='imm__pcppcf__features__feature'>
                                <SVGIcon src={Icons.CheckCircle} color="success" />
                                <p>Dedicated immigration consultant/lawyer</p>
                            </div>
                            <div className='imm__pcppcf__features__feature'>
                                <SVGIcon src={Icons.CheckCircle} color="success" />
                                <p>24-hour response time from our team</p>
                            </div>
                            <div className='imm__pcppcf__features__feature'>
                                <SVGIcon src={Icons.CheckCircle} color="success" />
                                <p>Select the best service suited for you! </p>
                            </div>
                            <div className='imm__pcppcf__features__feature'>
                                <SVGIcon src={Icons.CheckCircle} color="success" />
                                <p>Assistance with preparing application, reviewing documents, completing a full application and submission</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="imm__packages_component__plans__plan_card__body__column">
                    <div className='imm__packages_component__plans__plan_card__features__container'>
                        <div className="imm__pcppcf__head">
                            <p className="imm__pcppcf__title">Best Price Guarantee</p>
                        </div>

                        <div className='imm__pcppcf__feature_descr'>
                            <p>You can count on our prices being competitive with the best out there. Our trusted providers will match prices to a comparable offering.</p>
                            <p>* Terms and Conditions apply</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

const Packages = () => {
    const [activePlanType, setActivePlanType] = useState('monthly');

    const { data: packages } = useGetPackages(true);

    return (
        <div className="imm__packages_component">
            <div className="imm__packages_component__header">
                <div className='imm__packages_component__plan_switch'>
                    <button
                        className={`imm__packages_component__plan_switch__btn ${activePlanType === 'annual' ? `imm__packages_component__plan_switch__btn__active` : ''}`}
                        onClick={() => setActivePlanType('annual')}
                    >
                        Pay annually
                    </button>
                    <button
                        className={`imm__packages_component__plan_switch__btn ${activePlanType === 'monthly' ? `imm__packages_component__plan_switch__btn__active` : ''}`}
                        onClick={() => setActivePlanType('monthly')}
                    >
                        Pay monthly
                    </button>
                </div>

                <div className='imm__packages_component__header__note'>
                    <ImageAsset src={getLogoSet().Monogram} alt='' />
                    <h5>Paid subscribers get the full power of the Immproved system</h5>
                </div>
            </div>

            {!packages?.length
                ? <div className='imm__packages_component__loading'>
                    <i className='imm__list_loader' />
                    <h4>Loading packages...</h4>
                </div>
                : <div className="imm__packages_component__plans">
                    <PlanCard
                        title='Fall'
                        planType='monthly'
                        activePlanType={activePlanType}
                        setActivePlanType={setActivePlanType}
                        price={0}
                        price_descr={null}
                        features_title="Try it out for free"
                        features_descr={null}
                        features={[
                            "Up to 15 recommendations",
                            "Limited notifications",
                            "1 result calculations",
                            "Immigration status manager (limited time offer)",
                        ]}
                    />
                    <PlanCard
                        selectedPackage={packages[0]}
                        title={packages[0].title}
                        planType='monthly'
                        activePlanType={activePlanType}
                        setActivePlanType={setActivePlanType}
                        price={parseFloat(packages[0].amount)}
                        price_descr="(monthly - cancel anytime)"
                        features_title="Unlock the power of Immproved"
                        features_descr="Everything in Fall, plus:"
                        features={[
                            "Unlimited edits/updates",
                            "Unlimited result calculations",
                            "Immigration status manager",
                        ]}
                    />
                    <PlanCard
                        recommended={true}
                        selectedPackage={packages[1]}
                        title={packages[1].title}
                        planType='annual'
                        activePlanType={activePlanType}
                        setActivePlanType={setActivePlanType}
                        price={parseFloat(packages[1].amount)}
                        price_descr={`(annual charge of $${packages[1].amount})`}
                        features_title="Get expert support and more"
                        features_descr="Everything in Winter, plus:"
                        features={[
                            "Travel Log",
                            "Locked in pricing",
                            "1 General Consultation"
                        ]}
                        bonus_text="BONUS $150 (value)"
                    />
                    <PlanCard
                        selectedPackage={packages[2]}
                        title={packages[2].title}
                        planType='annual'
                        activePlanType={activePlanType}
                        setActivePlanType={setActivePlanType}
                        price={parseFloat(packages[2].amount)}
                        price_descr={`(annual charge of $${packages[2].amount})`}
                        features_title="Full assistance"
                        features_descr="Everything in Sping, plus:"
                        features={[
                            "15% discount on immigration related services",
                            "4 General Consultations",
                        ]}
                        bonus_text="BONUS $600 (value)"
                    />
                    <AllSeasonCard />
                </div>
            }
        </div>
    );
};

export default Packages;