import React from 'react';

import { useGetSVGContent } from '../../hooks/misc';

type SVGIconColor = 'primary' | 'success' | 'error' | 'yellow' | 'orange' | 'dark' | 'grey' | 'dark_grey' | 'white' | 'black';
type SVGIconHoverColor = 'primary' | 'dark_grey';

interface SVGIconProps {
    src: string | null;
    color?: SVGIconColor;
    hoverColor?: SVGIconHoverColor;
    rotation?: 0 | 90 | 180 | 270;
};
export const SVGIcon = (props: SVGIconProps) => {
    const { src, color = 'dark_grey', hoverColor, rotation = 0 } = props;

    const { data: svgContent } = useGetSVGContent(src);

    const getClassName = () => {
        let baseClass = 'imm__icon';
        if (color) baseClass += ` imm__icon__${color}`;
        if (hoverColor) baseClass += ` imm__icon__hover_${hoverColor}`;
        if (rotation !== undefined) baseClass += ` imm__icon__rotation_${rotation}deg`;
        if (!svgContent) baseClass += ' imm__icon__loading';
        return baseClass;
    };

    return (
        svgContent
            ? <div className={getClassName()} dangerouslySetInnerHTML={{ __html: svgContent }} />
            : <div className={getClassName()} />
    );
};