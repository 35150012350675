import React, { ImgHTMLAttributes, RefObject } from 'react';

import { useGetSignedUrl } from '../../hooks/misc';

interface ImageAssetProps extends ImgHTMLAttributes<HTMLImageElement> {
    custRef?: RefObject<HTMLImageElement>;
};
export const ImageAsset = (props: ImageAssetProps) => {
    const { src = null, alt = '', custRef, ...rest } = props;

    const { data: signedUrl } = useGetSignedUrl(src);

    return <img src={signedUrl ?? ''} alt={alt} ref={custRef} {...rest} />;
};