import React, { useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { isModalActive, closeModal, getModalData } from "../../store/slices/modal";
import { SVGIcon } from "../wrappers/SVGIcon";

import { Icons } from "../../data/Assets";

import useRespondToOrganizationInvitation from "../../hooks/invitations/useRespondToOrganizationInvitation";

import { getDateString } from "../../helpers/utility";

const modalName = "ORGANIZATION_INVITATION_MODAL";
const modalTitle = "Organization Invitation";
const modalClass = "organization_invitation";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const OrganizationInvitationModal = () => {

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));

    const [processingFor, setIsProcessingFor] = useState({ response: null, idx: null });
    const [apiError, setApiError] = useState({ message: null, idx: null });

    const { mutate: respondToOrganizationInvitation } = useRespondToOrganizationInvitation();

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    const getAddress = (employer: any) => {
        if (!employer) return '';

        const job = employer.jobs[0];
        if (!job) return '';

        let address = '';
        if (job.street) address += job.street;
        if (job.city) address += `, ${job.city}`;
        if (job.province) address += `, ${job.province}`;
        if (job.postal) address += `, ${job.postal}`;

        return address;
    };

    const onRespondToInvitation = (idx: any, invitationId: any, response: any) => {
        if (processingFor.response || processingFor.idx) return;

        setApiError({ message: null, idx: null });
        setIsProcessingFor({ response, idx });

        respondToOrganizationInvitation({
            idx,
            payload: {
                id: invitationId,
                status: response
            },
            setIsProcessingFor,
            setApiError,
        })
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className="imm__organization_invitation__inner_wrap">
                    <button className="close_btn" onClick={onCloseModal}>
                        <SVGIcon src={Icons.X} />
                    </button>

                    <h4 className="color_primary">Employer Invitation</h4>

                    {!!modalData &&
                        <p className="color_primary wt-600 pt-3">
                            {modalData?.payload?.organization_name}&nbsp;
                            <span className="color_disabled wt-500 pt-2">({getDateString(modalData.date_created, 'MMM DD-YYYY')})</span>
                        </p>
                    }

                    <p className="imm__organization_invitation__note mice pt-2">
                        Once you accept the invitation, the employer will have view-only access to your immigration status information and they won't be able to change or edit it. Their access will be limited to viewing only information related to passports, permits and visas.
                    </p>

                    {/* <p className="imm__organization_invitation__note mice pt-2">
                        Following are the employers associated with the organization.
                        All the employers will have read only access to your information.
                    </p> */}

                    {
                        !modalData?.payload?.employers?.length
                            ? <h5 className="color_error">No employers.</h5>
                            : <div className="imm__organization_invitation__list">
                                {
                                    modalData.payload.employers.map((employer: any, idx: number) =>
                                        <div className="imm__organization_invitation__item" key={idx}>
                                            <p className="color_primary wt-600">{employer.legal_name}</p>
                                            <p>{getAddress(employer)}</p>
                                        </div>
                                    )
                                }

                                <div className="imm__organization_invitation__action">
                                    <button
                                        className={`imm__regular__btn imm__btn__tertiary btn_fit_content`}
                                        onClick={() => onRespondToInvitation(1, modalData.id, 'refused')}
                                        disabled={processingFor.idx === 1}
                                    >
                                        {
                                            processingFor.response === 'refused'
                                                ? <i className='imm__btn_loader' />
                                                : 'Refuse Invitation'
                                        }
                                    </button>
                                    <button
                                        className={`imm__regular__btn imm__btn__primary btn_fit_content`}
                                        onClick={() => onRespondToInvitation(1, modalData.id, 'accepted')}
                                        disabled={processingFor.idx === 1}
                                    >
                                        {
                                            processingFor.response === 'accepted'
                                                ? <i className='imm__btn_loader' />
                                                : 'Accept Invitation'
                                        }
                                    </button>
                                </div>
                                {apiError.idx === 1 && <p className="text_center color_error mice wt-600 pt-3">{apiError.message}</p>}
                            </div>
                    }
                </div>
            </div>
        </ReactModal>
    );
};

export default OrganizationInvitationModal;