import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { getModalData, isModalActive, closeModal } from "../../store/slices/modal";

import { getTrackingPath } from "../../helpers/tracking";

import { SVGIcon } from "../wrappers/SVGIcon";

import { Icons } from "../../data/Assets";

const modalName = "INCOMPLETE_QUESTIONNAIRE_MODAL";
const modalTitle = "Incomplete Questionnaire";
const modalClass = "incomplete_questionnaire";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const IncompleteQuestionnaireModal = () => {

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible && !!modalData}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <button className="imm__modal_close_btn" onClick={onCloseModal}>
                    <SVGIcon src={Icons.X} />
                </button>
                <div className={`imm__modal_body imm__${modalClass}__modal_body`}>
                    {!!modalData &&
                        <div className="imm__questionnaire_completion_warning">
                            <SVGIcon src={Icons.ExclamationCircleFill} color="error" />
                            <h3 className='pt-4 pb-2'>Questionnaire incomplete</h3>
                            <p className='text_center'>
                                You have not completed all the required questions. Please review and complete the questionnaire before submitting.
                            </p>
                            <br />
                            <div className='incomplete_pages'>
                                <h4>Incomplete Pages:</h4>
                                {modalData.instanceData?.incompletePages?.map((iData: any) =>
                                    <div key={iData.page.id} className='incomplete_pages__page'>
                                        <p>{iData.page.title}</p>
                                        <button
                                            onClick={() => {
                                                modalData.setTriggerTargetPage({ pageId: iData.page.id, sectionId: iData.section.id });
                                                setTimeout(() => {
                                                    onCloseModal();
                                                }, 500);
                                            }}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                {!!modalData &&
                    <div className={`imm__modal_footer imm__${modalClass}__modal_footer`}>
                        <button
                            className='imm__regular__btn imm__btn__tertiary btn_fit_content'
                            onClick={() => {
                                modalData.setTriggerTargetPage(null);
                                window.location.href = getTrackingPath('/');
                            }}
                        >
                            Exit
                        </button>
                        <button
                            className='imm__regular__btn imm__btn__tertiary btn_fit_content'
                            onClick={() => {
                                modalData.setTriggerTargetPage(null);
                                onCloseModal();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            </div>
        </ReactModal>
    );
};

export default IncompleteQuestionnaireModal;