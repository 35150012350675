import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { toLower } from 'lodash';

import { getAuthProviders } from "../../services/auth_providers";

import { openModal, closeModal } from '../../store/slices/modal';

import useSendActivationEmail from "../auth/useSendActivationEmail";

const useGetAuthProviders = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { mutate: sendActivationEmailMutation } = useSendActivationEmail();

    const mutationFn = async (payload: any) => {
        return await getAuthProviders(payload.data);
    };

    return useMutation({
        mutationFn,
        onMutate: (variables: any) => {
            variables.setIsProcessing(true);
            variables.setApiError(null);
        },
        onSuccess: (response: any, variables: any) => {
            variables.successCallback(response.data);
        },
        onError: (error: any, variables: any) => {
            if (error?.response?.status === 409 && error?.response?.data?.message === 'User email is not verified.') {
                dispatch(openModal({
                    name: 'MESSAGE_MODAL',
                    data: {
                        closeOnEsc: true,
                        closeOnOutsideClick: true,
                        icon: 'error',
                        isLoading: isLoading,
                        title: 'Sign in Failed',
                        titleColor: 'error',
                        content: 'Your email is not verified. Please verify your email to sign in. If you did not receive the activation email, please check your spam folder or click the button below to resend the activation email.',
                        buttons: [
                            { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                            {
                                label: 'Resend Activation Email', style: 'primary', onClick: () => {
                                    setIsLoading(true);
                                    sendActivationEmailMutation({
                                        email: toLower(variables?.data?.userEmail || ''),
                                        callback: () => {
                                            // setLoading(false);
                                        },
                                        successCallback: () => {
                                            setIsLoading(false);
                                            dispatch(closeModal('MESSAGE_MODAL'));
                                            dispatch(openModal({
                                                name: 'MESSAGE_MODAL',
                                                data: {
                                                    closeOnEsc: true,
                                                    closeOnOutsideClick: true,
                                                    icon: 'success',
                                                    title: 'Activation Email Sent',
                                                    titleColor: 'success',
                                                    content: 'An activation email has been sent to your email address. Please check your email and follow the instructions to activate your account.',
                                                    buttons: [
                                                        { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                                                    ]
                                                }
                                            }));
                                        },
                                        errorCallback: (error: any) => {
                                            setIsLoading(false);
                                            dispatch(closeModal('MESSAGE_MODAL'));
                                            dispatch(openModal({
                                                name: 'MESSAGE_MODAL',
                                                data: {
                                                    closeOnEsc: true,
                                                    closeOnOutsideClick: true,
                                                    icon: 'error',
                                                    title: 'Activation Email Failed',
                                                    titleColor: 'error',
                                                    content: error?.message || 'An error occurred while sending the activation email. Please try again later.',
                                                    buttons: [
                                                        { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                                                    ]
                                                }
                                            }));
                                        },
                                    })

                                }
                            },
                        ]
                    },
                }));
                return;
            } else {
                variables.setApiError(error.response.data.message);
            };
        },
        onSettled: (data: any, error: any, variables: any) => {
            variables.setIsProcessing(false);
        },
    });
};
export default useGetAuthProviders;