import React from 'react';
import styled from 'styled-components';

import './index.css';

import { Images } from '../../../../data/Assets';
import { ImageAsset } from '../../../wrappers/ImageAsset';

const LoaderWrap = styled.div<{ overlay: boolean }>`
  ${({ overlay }) => overlay ? 'position: fixed;' : ''}
  height: 100vh;
  min-height: 300px;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderBody = styled.div`
  border-radius: 10px;
  background: white;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderIconBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingImg = styled.div`
  animation: spin-anim 2s linear infinite;
  -webkit-animation: spin-anim 2s linear infinite;
  -moz-animation: spin-anim 2s linear infinite;
  max-width: 50%;
  max-height: 50%;
  -o-object-fit: contain;
  object-fit: contain;
`;

const PageLoader = ({ overlay }: any) => {
  return (
    <LoaderWrap overlay={overlay}>
      <LoaderBody>
        <LoaderIconBox>
          <LoadingImg>
            <ImageAsset src={Images.Plane} style={{ width: '100%', height: '100%' }} />
          </LoadingImg>
        </LoaderIconBox>
      </LoaderBody>
    </LoaderWrap>
  )
}

export default PageLoader;