import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';

import { getSessionData, setSessionData } from '../../store/slices/global';
import { closeModal, getModalData, isModalActive } from '../../store/slices/modal';

import useSignIn from '../../hooks/auth/useSignIn';
import useGetAuthProviders from '../../hooks/auth_providers/useGetAuthProviders';
import useSetSignInData from '../../hooks/auth/useSetSignInData';
import useGenerateAuthProviderCode from '../../hooks/auth_providers/useGenerateAuthProviderCode';
import useRegisterAuthProvider from '../../hooks/auth_providers/useRegisterAuthProvider';
import useVerifyAuthProviderCode from '../../hooks/auth_providers/useVerifyAuthProviderCode';

import { getTrackingPath } from '../../helpers/tracking';

import { SVGIcon } from '../wrappers/SVGIcon';

import { Icons, Images } from '../../data/Assets';
import { ImageAsset } from '../wrappers/ImageAsset';

const modalName = "TWO_FACTOR_AUTHENTICATION_MODAL";
const modalClass = "two_factor_authentication";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const TwoFactorAuthenticationModal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sessionData = useSelector(getSessionData());
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));
    const modalTitle = modalData?.view === 'set-up' ? 'Enable Two Factor Authentication' : 'Two-step Verification';
    const setSignInData = useSetSignInData();

    const [userData, setUserData] = useState<{ email: string, password: string, oauth: boolean }>({ email: '', password: '', oauth: false });
    const [twoFactorEmail, setTwoFactorEmail] = useState<string>('');
    const [qrCode, setQrCode] = useState<string | null>(null);
    const [expiresIn, setExpiresIn] = useState<Date | null>(null);
    const [isProcessing, setIsProcessing] = useState(false); // eslint-disable-line
    const [verified, setVerified] = useState(false);
    const [apiError, setApiError] = useState<string | null>(null);
    const [code, setCode] = useState('');
    const [showOtpBox, setShowOtpBox] = useState(false);
    const [selectedAuthenticator, setSelectedAuthenticator] = useState<any>(null);
    const [authProviders, setAuthProviders] = useState([]);

    const { mutate: getAuthProviders } = useGetAuthProviders();
    const { mutate: registerAuthProvider } = useRegisterAuthProvider();
    const { mutate: generateAuthProviderCode } = useGenerateAuthProviderCode();
    const { mutate: verifyAuthProviderCode } = useVerifyAuthProviderCode();
    const { mutate: signInMutation } = useSignIn();

    useEffect(() => {
        if (modalData) {
            setUserData({
                email: modalData.email,
                password: modalData.password,
                oauth: false
            });

            if (modalData.providers) {
                setAuthProviders(modalData.providers);
            }
        }
    }, [modalData]) // eslint-disable-line 

    const onResetStates = () => {
        setTwoFactorEmail('');
        setQrCode(null);
        setExpiresIn(null);
        setIsProcessing(false);
        setVerified(false);
        setApiError(null);
        setCode('');
        setShowOtpBox(false);
        setVerified(false);
    }

    const onCloseModal = (e: any) => {
        e.preventDefault();
        onResetStates();
        setSelectedAuthenticator(null);
        dispatch(closeModal(modalName));
    };

    const authenticatorButtons = [
        {
            title: 'Verify with Google',
            type: 'google',
            icon: Images.GoogleAuthenticator
        },
        {
            title: 'Verify with Microsoft',
            type: 'microsoft',
            icon: Images.MicrosoftAuthenticator
        },
        {
            title: 'Verify with Email',
            type: 'email',
            icon: Images.EmailAuthenticator
        },
        // {
        //     title: 'Verify with Phone',
        //     type: 'phone',
        //     icon: Images.PhoneAuthenticator
        // },
    ]

    const onVerifyAuthProviderCode = () => {
        if (code?.length !== 6) {
            setApiError('Please enter the 6-digit code');
            return;
        }

        const payload = {
            type: selectedAuthenticator?.type,
            userEmail: userData?.email,
            code: code
        }

        verifyAuthProviderCode({
            data: payload,
            setIsProcessing,
            setApiError,
            successCallback: (data: any) => {
                onResetStates();
                setSelectedAuthenticator(null);

                if (modalData?.view === 'set-up') {
                    setVerified(true);

                    dispatch(setSessionData(
                        {
                            ...sessionData,
                            two_factor_enabled: true,
                        }
                    ));

                    getAuthProviders({
                        data: {
                            userEmail: userData?.email,
                        },
                        setIsProcessing,
                        setApiError,
                        successCallback: (data: any) => {
                            setAuthProviders(data);
                        }
                    });

                } else {
                    dispatch(closeModal(modalName));
                    signInMutation({
                        payload: {
                            email: (userData?.email?.toLowerCase())?.trim(),
                            password: userData.password,
                        },
                        callback: () => {
                            setIsProcessing(false);
                        },
                        successCallback: (data: any) => {
                            setSignInData(data);
                            navigate(getTrackingPath('/'), {
                                state: {
                                    recent_login: true
                                }
                            });
                        },
                        errorCallback: (error: any) => {
                            setApiError(error.response.data.message);
                        }
                    })
                }
            }
        });
    }

    const onGenerateAuthProviderCode = (authenticator: any) => {
        generateAuthProviderCode({
            data: {
                type: authenticator?.type ?? null,
                userEmail: userData?.email ?? null
            },
            setIsProcessing,
            setApiError,
            successCallback: (data: any) => {
                setExpiresIn(data.expires ? new Date(data.expires) : null);
                setShowOtpBox(true);
            }
        });
    };

    const onRegisterAuthProvider = (authenticator: any) => {
        registerAuthProvider({
            data: {
                type: authenticator?.type ?? null,
                source: authenticator?.type === 'email' ? twoFactorEmail : authenticator?.type === 'phone' ? (userData as any)?.phone : null,
            },
            setIsProcessing,
            setApiError,
            successCallback: (data: any) => {
                setQrCode(data.qr_code ?? null);
                setExpiresIn(data.expires ? new Date(data.expires) : null);
                setShowOtpBox(true);
            }
        })
    };

    const onSelectAuthenticator = (authenticator: any) => {
        onResetStates();
        setSelectedAuthenticator(authenticator);

        if (modalData?.view !== 'set-up') {
            if (authenticator?.type === 'phone' || authenticator?.type === 'email') {
                onGenerateAuthProviderCode(authenticator);
            } else {
                setShowOtpBox(true);
            }
            return;
        };

        if (authenticator?.type === 'email') {
            setTwoFactorEmail(userData?.email);
        }

        if (authenticator?.type === 'google' || authenticator?.type === 'microsoft') {
            onRegisterAuthProvider(authenticator);
        }
    };

    const onResetChosenAuthenticator = () => {
        onResetStates();
        setSelectedAuthenticator(null);
    };

    const renderAuthenticatorButtons = useCallback(() => {
        return authenticatorButtons.map((authenticator, index) => {
            const displayButton = modalData?.view !== 'set-up' ? !!authProviders.find((provider: any) => provider.type === authenticator.type) : true;
            const isRegistered = modalData?.view === 'set-up' ? !!authProviders.find((provider: any) => provider.type === authenticator.type) : false;

            return displayButton && (
                <button
                    key={index}
                    disabled={isRegistered}
                    className={`authenticator_btn ${isRegistered ? 'authenticator_btn_registered' : ''}`}
                    onClick={() => onSelectAuthenticator(authenticator)}
                >
                    <ImageAsset loading="eager" src={authenticator.icon} alt='' />
                    <SVGIcon src={Icons.CheckCircle} />
                </button>
            )
        })
    }, [authProviders, authenticatorButtons]) // eslint-disable-line

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className={`imm__${modalClass}_header`}>
                    <div className={`imm__${modalClass}_close_div`}>
                        <button className="close_btn" onClick={(e) => onCloseModal(e)}>
                            <SVGIcon src={Icons.X} />
                        </button>
                    </div>
                    <h3 className={`imm__${modalClass}_header_title`}>{modalTitle}</h3>
                    <span className={`imm__${modalClass}_header_subtitle`}>
                        This is an extra layer of security for your account.
                        Benefits include protection against unauthorized access and enhanced security.
                    </span>
                </div>
                <div className={`imm__${modalClass}_body`}>
                    {!selectedAuthenticator &&
                        <div className='authenticator_section'>
                            <h4>{!verified ? 'Choose your Authenticator' : 'Select another method'}</h4>
                            <div className='authenticator_btns'>
                                {renderAuthenticatorButtons()}
                            </div>
                        </div>
                    }
                    {selectedAuthenticator &&
                        <div className='authenticator_qr_code_container'>
                            <h4>{selectedAuthenticator.title}</h4>
                            <button disabled className='authenticator_btn'>
                                <ImageAsset loading="eager" src={selectedAuthenticator.icon} alt='' />
                            </button>

                            {modalData?.view === 'set-up' && qrCode && (selectedAuthenticator?.type === 'google' || selectedAuthenticator?.type === 'microsoft') &&
                                <>
                                    <h6> Please open your authenticator app and scan the QR code below to receive a 6-digit code.</h6>
                                    <div className='authenticator_qr_code_box'>
                                        <ImageAsset src={qrCode || ''} alt='' />
                                    </div>
                                </>
                            }

                            {modalData?.view === 'set-up' && selectedAuthenticator?.type === 'email' &&
                                <>
                                    <h6> Please enter the email address that you want to register for two-factor authentication, and we will send you a 6-digit code.</h6>
                                    <div className='authenticator_input_box'>
                                        <input
                                            type='email'
                                            placeholder='Email'
                                            value={twoFactorEmail}
                                            onChange={(e) => setTwoFactorEmail(e.target.value)}
                                        />
                                        <button className='get_code_btn' onClick={() => onRegisterAuthProvider(selectedAuthenticator)}>
                                            Get Code
                                        </button>
                                    </div>
                                </>
                            }

                            {showOtpBox &&
                                <>
                                    <h6> Enter the 6-digit code
                                        {selectedAuthenticator?.type === 'email'
                                            ? ` that is sent to ${(authProviders.find((provider: any) => provider.type === 'email') as any)?.email}`
                                            : selectedAuthenticator?.type === 'phone'
                                                ? ` that is sent to ${(authProviders.find((provider: any) => provider.type === 'phone') as any)?.phone}`
                                                : ' that is generated by your authenticator app'
                                        }
                                        &nbsp;to verify your account.
                                    </h6>


                                    <OtpInput
                                        value={code}
                                        onChange={setCode}
                                        numInputs={6}
                                        containerStyle={{ display: 'flex', justifyContent: 'center' }}
                                        inputStyle={{ width: '40px', height: '40px', margin: '0 8px', fontSize: '20px', borderRadius: '5px', border: '1px solid #CFCFCF' }}
                                        renderInput={(props) => <input {...props} />}
                                    />

                                    <span className='expires_in_text'>
                                        {!qrCode && expiresIn && `Code expires in ${moment(expiresIn).fromNow()}`}
                                    </span>
                                </>
                            }
                        </div>
                    }
                </div>
                {!showOtpBox && !verified && <br />}
                <div className={`imm__${modalClass}_footer`}>
                    {showOtpBox &&
                        <>
                            <button className='submit_btn' onClick={onVerifyAuthProviderCode}>
                                Verify
                            </button>
                            <div className='another_method_box'>
                                <button onClick={onResetChosenAuthenticator}>Or use another method</button>
                            </div>
                            {apiError &&
                                <div className='error_box'>
                                    <span>{apiError}</span>
                                </div>
                            }
                        </>
                    }
                    {verified &&
                        <div className='success_box'>
                            <p className='success_message'>Two-factor authentication has been successfully enabled.</p>
                            <span className='success_disclaimer'>You will now be required to enter a 6-digit code to access your account when you sign in.</span>
                            <div className='success_action_btns'>
                                <button className='close_btn' onClick={(e) => onCloseModal(e)}>Done</button>
                            </div>
                        </div>
                    }
                    {modalData?.view === 'set-up' && !verified && authProviders.length === authenticatorButtons.length && (
                        <span> All authenticators have been registered. </span>
                    )}
                </div>
            </div>
        </ReactModal >
    )
}

export default TwoFactorAuthenticationModal;