import React, { useState } from 'react'
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal, getModalData, isModalActive } from '../../store/slices/modal';

import { SVGIcon } from '../wrappers/SVGIcon';

import { Icons } from '../../data/Assets';

const modalName = "PROGRAM_FILTERS_MODAL";
const modalTitle = "Filter Programs";
const modalClass = "program_filters";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const ProgramFilterModal = () => {
    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));

    const [tagIds, setTagIds] = useState<any[]>([]);


    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    const onFilterChange = (e: any, tagId: any) => {
        if (e.target.checked) {
            setTagIds([...tagIds, tagId]);
        } else {
            setTagIds(tagIds.filter((id: any) => id !== tagId));
        }
    };

    const onApplyFilter = (e: any) => {
        e.preventDefault();
        modalData?.setSelectedFilters(tagIds);

        onCloseModal();
    }

    const onClearFilters = () => {
        setTagIds([]);

        modalData?.setSelectedFilters([]);
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className={`imm__${modalClass}_header`}>
                    <button className="close_btn" onClick={onCloseModal}>
                        <SVGIcon src={Icons.X} color="white" />
                    </button>
                    <h5 className={`imm__${modalClass}_header_title`}>{modalTitle}</h5>
                    <button onClick={onClearFilters} className={`imm__${modalClass}_clear_filter_btn`}>
                        Clear all
                    </button>
                </div>
                <div className={`imm__${modalClass}_body`}>
                    <h5>Filter by</h5>

                    <form className={`imm__${modalClass}_form`}>
                        <div className={`imm__${modalClass}_form_content`}>
                            {modalData?.filters.map((filter: any, index: any) => (
                                <div key={index} className={`imm__${modalClass}_form_group`}>
                                    <label className={`imm__${modalClass}_custom_checkbox`}>
                                        <input onChange={(e: any) => onFilterChange(e, filter.id)} className={`imm__${modalClass}_checkbox`} type="checkbox" id={filter.id} name={filter.name} value={filter.id} checked={tagIds.includes(filter.id)} />
                                        <span className={`imm__${modalClass}_checkmark`}>
                                            {tagIds.includes(filter.id) ? <SVGIcon src={Icons.Checkmark} /> : null}
                                        </span>
                                    </label>
                                    <label className={`imm__${modalClass}_label`} htmlFor={filter.id}>{filter.name}</label>
                                </div>
                            ))}
                        </div>
                        <button onClick={onApplyFilter} type="submit" className={`imm__${modalClass}_action_btn`}>
                            Apply
                        </button>
                    </form>
                </div>
            </div>
        </ReactModal>
    )
}

export default ProgramFilterModal;